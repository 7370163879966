.custom-modal {
    color: #fff; 
 /* background: linear-gradient(135deg, #ff7e5f, #feb47b);   */
    border-radius: 8px;
    width: 100%; 
    max-width: 500px; 
    padding: 20px; 
    margin: 0 auto; 
}


@media (max-width: 768px) {
    .custom-modal {
        max-width: 90%; 
        padding: 15px; 
    }
}

/* Further adjustments for mobile devices */
@media (max-width: 480px) {
    .custom-modal {
        max-width: 95%; 
        padding: 10px;
    }
}