@tailwind base;
@tailwind components;
@tailwind utilities;

.input {
    flex-shrink: 1;
    appearance: none;
    height: 3rem /* 48px */;
    padding-left: 1rem /* 16px */;
    padding-right: 1rem /* 16px */;
    font-size: 0.875rem /* 14px */;
    font-size: 1rem /* 16px */;
    line-height: 1.25rem /* 20px */;
    line-height: 2;
    line-height: 1.5rem /* 24px */;
    border-radius: var(--rounded-btn, 0.5rem /* 8px */);
    border-width: 1px;
    border-color: transparent;
    --tw-bg-opacity: 1;
    background-color: var(--fallback-b1,oklch(var(--b1)/var(--tw-bg-opacity)));
}
.input[type="number"]::-webkit-inner-spin-button,
.input-md[type="number"]::-webkit-inner-spin-button {
    margin-top: -1rem /* -16px */;
    margin-bottom: -1rem /* -16px */;
    margin-inline-end: -1rem /* -16px */;
}
.input input {
    --tw-bg-opacity: 1;
    background-color: var(--fallback-p,oklch(var(--p)/var(--tw-bg-opacity)));
    background-color: transparent;
}
.input input:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.input[list]::-webkit-calendar-picker-indicator {
    line-height: 1em;
}
.input:focus,
  .input:focus-within {
    box-shadow: none;
    border-color: var(--fallback-bc,oklch(var(--bc)/0.2));
    outline-style: solid;
    outline-width: 2px;
    outline-offset: 2px;
    outline-color: var(--fallback-bc,oklch(var(--bc)/0.2));
}
.input:focus,
  .input:focus-within {
    box-shadow: none;
    border-color: var(--fallback-bc,oklch(var(--bc)/0.2));
    outline-style: solid;
    outline-width: 2px;
    outline-offset: 2px;
    outline-color: var(--fallback-bc,oklch(var(--bc)/0.2));
}
.input-disabled,
  .input:disabled,
  .input[disabled] {
    cursor: not-allowed;
    --tw-border-opacity: 1;
    border-color: var(--fallback-b2,oklch(var(--b2)/var(--tw-border-opacity)));
    --tw-bg-opacity: 1;
    background-color: var(--fallback-b2,oklch(var(--b2)/var(--tw-bg-opacity)));
    color: var(--fallback-bc,oklch(var(--bc)/0.4));
}
.input-disabled,
  .input:disabled,
  .input[disabled] {
    cursor: not-allowed;
    --tw-border-opacity: 1;
    border-color: var(--fallback-b2,oklch(var(--b2)/var(--tw-border-opacity)));
    --tw-bg-opacity: 1;
    background-color: var(--fallback-b2,oklch(var(--b2)/var(--tw-bg-opacity)));
    color: var(--fallback-bc,oklch(var(--bc)/0.4));
}
.input-disabled::placeholder,
  .input:disabled::placeholder,
  .input[disabled]::placeholder {
    color: var(--fallback-bc,oklch(var(--bc)/var(--tw-placeholder-opacity)));
    --tw-placeholder-opacity: 0.2;
}
.input-disabled::placeholder,
  .input:disabled::placeholder,
  .input[disabled]::placeholder {
    color: var(--fallback-bc,oklch(var(--bc)/var(--tw-placeholder-opacity)));
    --tw-placeholder-opacity: 0.2;
}
.input::-webkit-date-and-time-value {
    text-align: inherit;
}
.mockup-browser .mockup-browser-toolbar .input {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 1.75rem /* 28px */;
    width: 24rem /* 384px */;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    --tw-bg-opacity: 1;
    background-color: var(--fallback-b2,oklch(var(--b2)/var(--tw-bg-opacity)));
    padding-left: 2rem /* 32px */;
    direction: ltr;
}
.mockup-browser .mockup-browser-toolbar .input:before {
    content: "";
    position: absolute;
    left: 0.5rem /* 8px */;
    top: 50%;
    aspect-ratio: 1 / 1;
    height: 0.75rem /* 12px */;
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    border-radius: 9999px;
    border-width: 2px;
    border-color: currentColor;
    opacity: 0.6;
}
.mockup-browser .mockup-browser-toolbar .input:after {
    content: "";
    position: absolute;
    left: 1.25rem /* 20px */;
    top: 50%;
    height: 0.5rem /* 8px */;
    --tw-translate-y: 25%;
    --tw-rotate: -45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    border-radius: 9999px;
    border-width: 1px;
    border-color: currentColor;
    opacity: 0.6;
}

.input-bordered {
    border-color: var(--fallback-bc,oklch(var(--bc)/0.2));
}


/* src/styles/global.css */
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .animate-fadeIn {
    animation: fadeIn 1s ease-out;
  }
  





/* 
.btn {
    display: inline-flex;
    height: 3rem;
    min-height: 3rem;
    flex-shrink: 0;
    cursor: pointer;
    user-select: none;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border-radius: var(--rounded-btn, 0.5rem);
    border-color: transparent;
    border-color: oklch(var(--btn-color, var(--b2)) / var(--tw-border-opacity));
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1em;
    gap: 0.5rem;
    font-weight: 600;
    text-decoration-line: none;
    text-decoration-line: none;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    border-width: var(--border-btn, 1px);
    animation: button-pop var(--animation-btn, 0.25s) ease-out;
    transition-property: color, background-color, border-color, opacity, box-shadow, transform;
    --tw-text-opacity: 1;
    color: var(--fallback-bc,oklch(var(--bc)/var(--tw-text-opacity)));
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    outline-color: var(--fallback-bc,oklch(var(--bc)/1));
    background-color: oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity));
    --tw-bg-opacity: 1;
    --tw-border-opacity: 1;
}
.btn-disabled,
  .btn[disabled],
  .btn:disabled {
    pointer-events: none;
}
.btn-disabled,
  .btn[disabled],
  .btn:disabled {
    pointer-events: none;
}
:where(.btn:is(input[type="checkbox"])),
:where(.btn:is(input[type="radio"])) {
    width: auto;
    appearance: none;
}
:where(.btn:is(input[type="checkbox"])),
:where(.btn:is(input[type="radio"])) {
    width: auto;
    appearance: none;
}
.btn:is(input[type="checkbox"]):after,
.btn:is(input[type="radio"]):after {
    --tw-content: attr(aria-label);
    content: var(--tw-content);
}
.btn:is(input[type="checkbox"]):after,
.btn:is(input[type="radio"]):after {
    --tw-content: attr(aria-label);
    content: var(--tw-content);
}
@media (hover: hover) {
    .btn:hover {
        --tw-border-opacity: 1;
        border-color: var(--fallback-b3,oklch(var(--b3)/var(--tw-border-opacity)));
        --tw-bg-opacity: 1;
        background-color: var(--fallback-b3,oklch(var(--b3)/var(--tw-bg-opacity)));
    }
    @supports (color: color-mix(in oklab, black, black)) {
        .btn:hover {
            background-color: color-mix(
            in oklab,
            oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity, 1)) 90%,
            black
          );
            border-color: color-mix(
            in oklab,
            oklch(var(--btn-color, var(--b2)) / var(--tw-border-opacity, 1)) 90%,
            black
          );
        }
    }
    @supports not (color: oklch(0% 0 0)) {
        .btn:hover {
            background-color: var(--btn-color, var(--fallback-b2));
            border-color: var(--btn-color, var(--fallback-b2));
        }
    }
}
@media (hover: hover) {
    .btn:hover {
        --tw-border-opacity: 1;
        border-color: var(--fallback-b3,oklch(var(--b3)/var(--tw-border-opacity)));
        --tw-bg-opacity: 1;
        background-color: var(--fallback-b3,oklch(var(--b3)/var(--tw-bg-opacity)));
    }
    @supports (color: color-mix(in oklab, black, black)) {
        .btn:hover {
            background-color: color-mix(
            in oklab,
            oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity, 1)) 90%,
            black
          );
            border-color: color-mix(
            in oklab,
            oklch(var(--btn-color, var(--b2)) / var(--tw-border-opacity, 1)) 90%,
            black
          );
        }
    }
    @supports not (color: oklch(0% 0 0)) {
        .btn:hover {
            background-color: var(--btn-color, var(--fallback-b2));
            border-color: var(--btn-color, var(--fallback-b2));
        }
    }
}
@media (hover: hover) {
    .btn:hover {
        --tw-border-opacity: 1;
        border-color: var(--fallback-b3,oklch(var(--b3)/var(--tw-border-opacity)));
        --tw-bg-opacity: 1;
        background-color: var(--fallback-b3,oklch(var(--b3)/var(--tw-bg-opacity)));
    }
    @supports (color: color-mix(in oklab, black, black)) {
        .btn:hover {
            background-color: color-mix(
            in oklab,
            oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity, 1)) 90%,
            black
          );
            border-color: color-mix(
            in oklab,
            oklch(var(--btn-color, var(--b2)) / var(--tw-border-opacity, 1)) 90%,
            black
          );
        }
    }
    @supports not (color: oklch(0% 0 0)) {
        .btn:hover {
            background-color: var(--btn-color, var(--fallback-b2));
            border-color: var(--btn-color, var(--fallback-b2));
        }
    }
}
@media (hover: hover) {
    .btn.glass:hover {
        --glass-opacity: 25%;
        --glass-border-opacity: 15%;
    }
}
@media (hover: hover) {
    .btn-disabled:hover,
    .btn[disabled]:hover,
    .btn:disabled:hover {
        --tw-border-opacity: 0;
        background-color: var(--fallback-n,oklch(var(--n)/var(--tw-bg-opacity)));
        --tw-bg-opacity: 0.2;
        color: var(--fallback-bc,oklch(var(--bc)/var(--tw-text-opacity)));
        --tw-text-opacity: 0.2;
    }
}
@media (hover: hover) {
    .btn-disabled:hover,
    .btn[disabled]:hover,
    .btn:disabled:hover {
        --tw-border-opacity: 0;
        background-color: var(--fallback-n,oklch(var(--n)/var(--tw-bg-opacity)));
        --tw-bg-opacity: 0.2;
        color: var(--fallback-bc,oklch(var(--bc)/var(--tw-text-opacity)));
        --tw-text-opacity: 0.2;
    }
}
@media (hover: hover) {
    @supports (color: color-mix(in oklab, black, black)) {
        .btn:is(input[type="checkbox"]:checked):hover, .btn:is(input[type="radio"]:checked):hover {
            background-color: color-mix(in oklab, var(--fallback-p,oklch(var(--p)/1)) 90%, black);
            border-color: color-mix(in oklab, var(--fallback-p,oklch(var(--p)/1)) 90%, black);
        }
    }
}
@media (hover: hover) {
    @supports (color: color-mix(in oklab, black, black)) {
        .btn:is(input[type="checkbox"]:checked):hover, .btn:is(input[type="radio"]:checked):hover {
            background-color: color-mix(in oklab, var(--fallback-p,oklch(var(--p)/1)) 90%, black);
            border-color: color-mix(in oklab, var(--fallback-p,oklch(var(--p)/1)) 90%, black);
        }
    }
}
.btn:active:hover,
  .btn:active:focus {
    animation: button-pop 0s ease-out;
    transform: scale(var(--btn-focus-scale, 0.97));
}
.btn:active:hover,
  .btn:active:focus {
    animation: button-pop 0s ease-out;
    transform: scale(var(--btn-focus-scale, 0.97));
}
@supports not (color: oklch(0% 0 0)) {
    .btn {
        background-color: var(--btn-color, var(--fallback-b2));
        border-color: var(--btn-color, var(--fallback-b2));
    }
}
.btn:focus-visible {
    outline-style: solid;
    outline-width: 2px;
    outline-offset: 2px;
}
.btn.glass {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    outline-color: currentColor;
}
.btn.glass.btn-active {
    --glass-opacity: 25%;
    --glass-border-opacity: 15%;
}
.btn.btn-disabled,
  .btn[disabled],
  .btn:disabled {
    --tw-border-opacity: 0;
    background-color: var(--fallback-n,oklch(var(--n)/var(--tw-bg-opacity)));
    --tw-bg-opacity: 0.2;
    color: var(--fallback-bc,oklch(var(--bc)/var(--tw-text-opacity)));
    --tw-text-opacity: 0.2;
}
.btn.btn-disabled,
  .btn[disabled],
  .btn:disabled {
    --tw-border-opacity: 0;
    background-color: var(--fallback-n,oklch(var(--n)/var(--tw-bg-opacity)));
    --tw-bg-opacity: 0.2;
    color: var(--fallback-bc,oklch(var(--bc)/var(--tw-text-opacity)));
    --tw-text-opacity: 0.2;
}
.btn.btn-disabled,
  .btn[disabled],
  .btn:disabled {
    --tw-border-opacity: 0;
    background-color: var(--fallback-n,oklch(var(--n)/var(--tw-bg-opacity)));
    --tw-bg-opacity: 0.2;
    color: var(--fallback-bc,oklch(var(--bc)/var(--tw-text-opacity)));
    --tw-text-opacity: 0.2;
}
.btn:is(input[type="checkbox"]:checked),
.btn:is(input[type="radio"]:checked) {
    --tw-border-opacity: 1;
    border-color: var(--fallback-p,oklch(var(--p)/var(--tw-border-opacity)));
    --tw-bg-opacity: 1;
    background-color: var(--fallback-p,oklch(var(--p)/var(--tw-bg-opacity)));
    --tw-text-opacity: 1;
    color: var(--fallback-pc,oklch(var(--pc)/var(--tw-text-opacity)));
}
.btn:is(input[type="checkbox"]:checked),
.btn:is(input[type="radio"]:checked) {
    --tw-border-opacity: 1;
    border-color: var(--fallback-p,oklch(var(--p)/var(--tw-border-opacity)));
    --tw-bg-opacity: 1;
    background-color: var(--fallback-p,oklch(var(--p)/var(--tw-bg-opacity)));
    --tw-text-opacity: 1;
    color: var(--fallback-pc,oklch(var(--pc)/var(--tw-text-opacity)));
}
.btn:is(input[type="checkbox"]:checked):focus-visible, .btn:is(input[type="radio"]:checked):focus-visible {
    outline-color: var(--fallback-p,oklch(var(--p)/1));
}
.btn:is(input[type="checkbox"]:checked):focus-visible, .btn:is(input[type="radio"]:checked):focus-visible {
    outline-color: var(--fallback-p,oklch(var(--p)/1));
} */