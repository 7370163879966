/* .customModal {
    background: #eee;
    background-color: aquamarine;
    max-width: 500px;
    width: 100%;
    border-radius: 10px
  } */

  /* customstyle.css */

  .customModal {
    width: 80%;
    max-width: 600px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .customModal .h-100 {
    height: 100%;
  }
  
  .customModal .table {
    width: 100%;
    margin-top: 1rem;
  }
  
  .customModal .table th,
  .customModal .table td {
    padding: 0.5rem;
    text-align: center;
  }
  
  .customModal .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    gap: 1rem;
  }
  
  .customModal .btn {
    background-color: #0a3b93;
    color: white;
    width: 6rem;
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .customModal .btn:hover {
    background-color: #092d72;
  }
  