
.custom-modal {
  max-width: 800px; /* Adjust this value as needed */
  width: 100%; /* Ensure it takes full width up to max */
  padding: 20px; /* Optional: Add padding for content */
}
.pagination-container {
    display: flex;
    list-style-type: none;
  
   
  
    .pagination-item {
      padding: 0 12px;
      height: 32px;
      text-align: center;
      margin: auto 4px;
      color: rgba(0, 0, 0, 0.87);
      display: flex;
      box-sizing: border-box;
      align-items: center;
      letter-spacing: 0.01071em;
      border-radius: 16px;
      line-height: 1.43;
      font-size: 13px;
      .dark{
        color:white;
      }
  
      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }
  
      &.selected {
        background-color: rgba(0, 0, 0, 0.08);
      }
  
      .arrow {
        &::before {
          position: relative;
          /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
          content: '';
          /* By using an em scale, the arrows will size with the font */
          display: inline-block;
          width: 0.4em;
          height: 0.4em;
          border-right: 0.12em solid rgba(0, 0, 0, 0.87);
          border-top: 0.12em solid rgba(0, 0, 0, 0.87);
        }
  
        &.left {
          transform: rotate(-135deg) translate(-50%);
        }
  
        &.right {
          transform: rotate(45deg);
        }
      }
  
      &.disabled {
        pointer-events: none;
  
        .arrow::before {
          border-right: 0.12em solid rgba(0, 0, 0, 0.43);
          border-top: 0.12em solid rgba(0, 0, 0, 0.43);
        }
  
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: var(--rounded-box, 1rem/* 16px */);
  }
  .card:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .card figure {
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }
  .card.image-full {
    display: grid;
  }
  .card.image-full:before {
    position: relative;
    content: "";
    z-index: 10;
    border-radius: var(--rounded-box, 1rem/* 16px */);
    --tw-bg-opacity: 1;
    background-color: var(--fallback-n,oklch(var(--n)/var(--tw-bg-opacity)));
    opacity: 0.75;
  }
  .card.image-full:before,
    .card.image-full > * {
    grid-column-start: 1;
    grid-row-start: 1;
  }
  .card.image-full:before,
    .card.image-full > * {
    grid-column-start: 1;
    grid-row-start: 1;
  }
  .card.image-full > figure img {
    height: 100%;
    object-fit: cover;
  }
  .card.image-full > .card-body {
    position: relative;
    z-index: 20;
    --tw-text-opacity: 1;
    color: var(--fallback-nc,oklch(var(--nc)/var(--tw-text-opacity)));
  }
  .card :where(figure:first-child) {
    overflow: hidden;
    border-start-start-radius: inherit;
    border-start-end-radius: inherit;
    border-end-start-radius: unset;
    border-end-end-radius: unset;
  }
  .card :where(figure:last-child) {
    overflow: hidden;
    border-start-start-radius: unset;
    border-start-end-radius: unset;
    border-end-start-radius: inherit;
    border-end-end-radius: inherit;
  }
  .card:focus-visible {
    outline: 2px solid currentColor;
    outline-offset: 2px;
  }
  .card.bordered {
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: var(--fallback-b2,oklch(var(--b2)/var(--tw-border-opacity)));
  }
  .card.compact .card-body {
    padding: 1rem/* 16px */;
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
  }
  .card.image-full :where(figure) {
    overflow: hidden;
    border-radius: inherit;
  }

  .btn {
    display: inline-flex;
    height: 2rem/* 48px */;
    min-height: 3rem/* 48px */;
    flex-shrink: 0;
    cursor: pointer;
    user-select: none;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border-radius: var(--rounded-btn, 0.5rem/* 8px */);
    border-color: transparent;
    border-color: oklch(var(--btn-color, var(--b2)) / var(--tw-border-opacity));
    padding-left: 1rem/* 16px */;
    padding-right: 1rem/* 16px */;
    text-align: center;
    font-size: 0.875rem/* 14px */;
    line-height: 1em;
    gap: 0.5rem/* 8px */;
    font-weight: 600;
    text-decoration-line: none;
    text-decoration-line: none;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    border-width: var(--border-btn, 1px);
}

.badge-primary {
 
}
.badge-outline.badge-primary {
  --tw-text-opacity: 1;
  color: var(--fallback-p,oklch(var(--p)/var(--tw-text-opacity)));
}
